import { useState, useEffect } from 'react';
import logo from './assets/logo.png';
import './App.css';
import NavigationBar from './components/NavigationBar';
import Mint from './components/Mint';
import Footer from './components/Footer';
import Mobile from './components/Mobile';


function App() {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
  if (window.innerWidth < 1400) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }


  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  return (
    <div className="App">
      <NavigationBar/>
      {!isMobile &&
        <>
          <Mint/>
        </>
      }
      {
        isMobile &&
        <Mobile/>
      }
      <Footer/>
    </div>
  );
}

export default App;
