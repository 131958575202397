import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const NavigationBar = (props) => {
	return (
			<Navbar className="NavigationBar">
				<Container >
					<Navbar.Brand href="/">
						<Image
							src={require("../assets/logo.png")}
						/>
					</Navbar.Brand>
					<Navbar.Brand href="/">
						<Image
							src={require("../assets/logo2.png")}
						/>
					</Navbar.Brand>
					<div className="socialLogos">
						<Nav.Link 
							target="_blank" 
							rel="noopener noreferrer" 
							href="https://discord.gg/mwvguybFYg"
						>
							<Image
								className="socialLogo"
								src={require("../assets/DiscordLogo.png")}
							/>
						</Nav.Link>
						<Nav.Link 
							target="_blank" 
							rel="noopener noreferrer" 
							href="https://x.com/moody_melons"
						>
							<Image
								className="socialLogo"
								src={require("../assets/TwitterLogo.png")}
							/>
						</Nav.Link>
					</div>
				</Container>
			</Navbar>
	)
}

export default NavigationBar;