import Image from 'react-bootstrap/Image';

const Mobile = (props) => {
	return (
		<div className="mobileView poppins-bold">
			For best minting experience, please come back on a desktop computer.
		</div>
	)
}

export default Mobile;