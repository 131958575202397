import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import preview from '../assets/preview.gif';
import welcome from '../assets/welcome.png';
import abi from '../abi.json';
import { ethers } from "ethers";


const Mint = (props) => {
	const contractABI = JSON.parse(JSON.stringify(abi));
	const [totalSupply, setTotalSupply] = useState("?");
	const [provider, setProvider] = useState(null);
	const [signer, setSigner] = useState(null);
	const [contract, setContract] = useState(null);
	const [mintAmount, setMintAmount] = useState(1);

	async function connectWeb3 () {
		if (window.ethereum) {
		    // Connect to the MetaMask EIP-1193 object. This is a standard
		    // protocol that allows Ethers access to make all read-only
		    // requests through MetaMask.
		    const pvd = new ethers.BrowserProvider(window.ethereum)
		    setProvider(pvd)

		    // It also provides an opportunity to request access to write
		    // operations, which will be performed by the private key
		    // that MetaMask manages for the user.
		    const sgn = await pvd.getSigner()
		    setSigner(sgn)
		}
	}

	async function mint() {
		if (contract) {
			console.log(contract.name())
			try {
				await contract.mint(mintAmount);
			} catch (e) {
				alert("An error occurred. 1 Free mint allowed per wallet.")
			}
			
		}
	}

	const incrementMintAmount = () => {
		if (mintAmount < 4) {
			setMintAmount(mintAmount + 1)
		}
	}

	const decrementMintAmount = () => {
		if (mintAmount > 1) {
			setMintAmount(mintAmount - 1)
		}
	}

	useEffect(() => {
    	if (signer) {
    		const ctr = new ethers.Contract("0xbA162d0Bc3b06250C550102208A52ac4AC58FFb3", contractABI, signer)
    		setContract(ctr)
    	}
  	}, signer)

	useEffect(() => {
		(async () => {
			if (contract) {
				const sply = await contract.totalSupply()
				setTotalSupply(Number(sply))
			}
		})();
  	}, contract)


	return (
		<Container className="mintContainer">
			<Row>
				<Col>
					<Image
						className="preview"
						src={preview}
						height={500}
						width={500}
					/>
				</Col>
				<Col>
					<Image
						className="welcome"
						src={welcome}
					/>
					<div className="introduction poppins-bold">
						Moody Melons is a collection 10,000 of sweet, cute, & refreshing  
						NFT’s living on the Ethereum blockchain, created from a combination of randomly 
						generated traits with varying rarity.
						<span className="oneOfone"> There will be some unique 1 of 1s in the collection! </span>
						If you are lucky, you might just mint a super rare Moody Melon from the cutest collection.					
						<span className="oneOfone"> The first 2,000 Moody Melons minted will be free!</span>(4 mints/wallet)
						</div>
					<Row>
						<Col>
							{
								!provider &&
								<Button 
									size="lg" 
									className="mintButton poppins-bold"
									onClick={() => connectWeb3()}
								>
							 		Connect Web3
								</Button>
							}
							{
								provider &&
								<Button 
									size="lg" 
									className="mintButton poppins-bold"
									onClick={() => mint()}
								>
						 			Mint
								</Button>
							}
						</Col>
						<Col>
							<Button
								className="mintAmountButton mintButton poppins-bold"
								onClick={() => decrementMintAmount()}
							>
							 -
							</Button>
							<span className="poppins-bold">
								{mintAmount}
							</span>
							<Button
								className="mintAmountButton mintButton poppins-bold"
								onClick={() => incrementMintAmount()}
							>
							 +
							</Button>
						</Col>
						<Col>
							<div className="numMinted poppins-bold">
								{totalSupply} / 10000 Minted
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
}

export default Mint;